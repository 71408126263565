<template>
  <div class="outer-page">
    <news-banner></news-banner>
  </div>
</template>

<script>
import newsBanner from "@components/newsBanner";
export default {
  name: "",
  components: {
    newsBanner,
  },
  data() {
    return {};
  },
};
</script>
<style lang="less" scoped>
@import url("./index.less");
</style>